import React from "react";

function PotentialInjury() {
    return (
        <svg
            width="103"
            height="103"
            viewBox="0 0 103 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="51.5" cy="51.5" r="51.5" fill="white" />
            <path
                d="M63.3054 53.4002H52.8795C52.4115 53.4002 52.0308 53.0263 52.0308 52.5675V30.6911C52.0308 28.6556 50.3446 27 48.2721 27L31.7587 27.0004C29.6859 27.0004 28 28.6563 28 30.6914V70.7056C28 74.1765 30.8752 77 34.4096 77H63.3055C69.9312 77 75.3214 71.7065 75.3214 65.2011C75.3214 58.6941 69.931 53.4002 63.3055 53.4002H63.3054ZM70.1368 57.9997L57.707 67.4495L50.4949 58.4411L54.2328 55.3539H63.3058C65.943 55.3535 68.3452 56.3589 70.1368 57.9997ZM29.9884 42.2633L34.7308 40.6483L41.5182 45.5853L29.9881 52.079L29.9884 42.2633ZM37.0064 39.8724L50.0415 35.4325V49.3543L37.0064 39.8724ZM29.9884 54.3306L43.2635 46.8546L50.0424 51.7856V52.5679C50.0424 53.6298 50.65 54.5541 51.5427 55.0243L40.2674 64.338L29.9886 57.2632L29.9884 54.3306ZM48.9728 59.6995L61.2594 75.0471H55.828L41.9534 65.4976L48.9728 59.6995ZM31.7591 28.954H48.2736C49.2496 28.954 50.0435 29.7337 50.0435 30.6921V33.3663L29.9896 40.1972L29.9892 30.6917C29.9892 29.7333 30.7831 28.954 31.7591 28.954H31.7591ZM29.9892 70.7061V59.6481L52.363 75.0471H34.4096C31.9715 75.0471 29.9886 73.0994 29.9886 70.7059L29.9892 70.7061ZM63.7805 75.0362L58.9374 68.9861L71.4551 59.4693C72.6368 61.085 73.3338 63.0644 73.3338 65.2012C73.333 70.4738 69.0898 74.7923 63.7801 75.0362H63.7805Z"
                fill="#1F78A7"
            />
        </svg>
    );
}

export default PotentialInjury;
