import React from "react";

function FreeEstimate() {
    return (
        <svg
            width="103"
            height="103"
            viewBox="0 0 103 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="51.5" cy="51.5" r="51.5" fill="white" />
            <path
                d="M72.6623 32.6273C72.6623 32.055 72.2895 31.5914 71.8292 31.5914H66.3366L66.3363 29.0722C66.3363 27.9297 65.5886 27 64.6697 27H61.9705C61.0519 27 60.3039 27.9292 60.3039 29.0722V31.5914H43.3654L43.3658 29.0722C43.3658 27.9297 42.6181 27 41.6992 27H39C38.0814 27 37.3334 27.9292 37.3334 29.0722V31.5914H31.8331C31.3724 31.5914 31 32.0554 31 32.6273V75.9641C31 76.5365 31.3728 77 31.8331 77H71.829C72.2897 77 72.6621 76.536 72.6621 75.9641L72.6625 43.4479C72.6628 43.4392 72.6667 43.4319 72.6667 43.4228C72.6667 43.4137 72.6628 43.4067 72.6625 43.3976L72.6623 32.6273ZM61.9706 32.6325C61.9706 32.6308 61.9713 32.6295 61.9713 32.6273C61.9713 32.6256 61.9706 32.6243 61.9706 32.6221V29.0722L64.6697 29.0709V36.1868H61.9706V32.6325ZM38.9997 29.0722L41.6989 29.0713V32.6225C41.6989 32.6243 41.6982 32.6256 41.6982 32.6277C41.6982 32.6295 41.6989 32.6308 41.6989 32.633V36.1873H38.9997V29.0722ZM70.9955 74.9288H32.6664V44.4584H70.9955V74.9288ZM70.9955 42.387H32.6664V33.6637H37.3339V36.1868C37.3339 37.3294 38.0816 38.2591 39.0005 38.2591H41.6997C42.6183 38.2591 43.3663 37.3298 43.3663 36.1868V33.6637H60.3047V36.1868C60.3047 37.3294 61.0524 38.2591 61.9714 38.2591H64.6705C65.5891 38.2591 66.3372 37.3298 66.3372 36.1868V33.6637H70.9966L70.9955 42.387Z"
                fill="#1F78A7"
            />
            <path
                d="M36.7857 51.6977H39.9347C40.3954 51.6977 40.7678 51.2337 40.7678 50.6618C40.7678 50.0894 40.395 49.6259 39.9347 49.6259L36.7857 49.6255C36.325 49.6255 35.9525 50.0894 35.9525 50.6614C35.9525 51.2337 36.325 51.6977 36.7857 51.6977V51.6977Z"
                fill="#1F78A7"
            />
            <path
                d="M45.7669 51.6977H48.9166C49.3773 51.6977 49.7498 51.2337 49.7498 50.6618C49.7498 50.0894 49.377 49.6259 48.9166 49.6259H45.7669C45.3062 49.6259 44.9337 50.0899 44.9337 50.6618C44.9337 51.2337 45.3065 51.6977 45.7669 51.6977Z"
                fill="#1F78A7"
            />
            <path
                d="M54.7499 51.6977H57.8989C58.3596 51.6977 58.732 51.2337 58.732 50.6618C58.732 50.0894 58.3592 49.6259 57.8989 49.6259H54.7499C54.2892 49.6259 53.9167 50.0899 53.9167 50.6618C53.9167 51.2337 54.2892 51.6977 54.7499 51.6977Z"
                fill="#1F78A7"
            />
            <path
                d="M63.732 51.6977H66.8817C67.3424 51.6977 67.7149 51.2337 67.7149 50.6618C67.7149 50.0894 67.3421 49.6259 66.8817 49.6259H63.732C63.2713 49.6259 62.8989 50.0899 62.8989 50.6618C62.8989 51.2337 63.2717 51.6977 63.732 51.6977V51.6977Z"
                fill="#1F78A7"
            />
            <path
                d="M36.7857 60.73H39.9347C40.3954 60.73 40.7678 60.2661 40.7678 59.6942C40.7678 59.1218 40.395 58.6583 39.9347 58.6583L36.7857 58.6578C36.325 58.6578 35.9525 59.1218 35.9525 59.6937C35.9525 60.2661 36.325 60.73 36.7857 60.73V60.73Z"
                fill="#1F78A7"
            />
            <path
                d="M45.7669 60.73H48.9166C49.3773 60.73 49.7498 60.2661 49.7498 59.6941C49.7498 59.1218 49.377 58.6583 48.9166 58.6583H45.7669C45.3062 58.6583 44.9337 59.1222 44.9337 59.6941C44.9337 60.2661 45.3065 60.73 45.7669 60.73Z"
                fill="#1F78A7"
            />
            <path
                d="M54.7499 60.73H57.8989C58.3596 60.73 58.732 60.2661 58.732 59.6941C58.732 59.1218 58.3592 58.6583 57.8989 58.6583H54.7499C54.2892 58.6583 53.9167 59.1222 53.9167 59.6941C53.9167 60.2661 54.2892 60.73 54.7499 60.73Z"
                fill="#1F78A7"
            />
            <path
                d="M63.732 60.73H66.8817C67.3424 60.73 67.7149 60.2661 67.7149 59.6941C67.7149 59.1218 67.3421 58.6583 66.8817 58.6583H63.732C63.2713 58.6583 62.8989 59.1222 62.8989 59.6941C62.8989 60.2661 63.2717 60.73 63.732 60.73V60.73Z"
                fill="#1F78A7"
            />
            <path
                d="M36.7857 69.7635H39.9347C40.3954 69.7635 40.7678 69.2995 40.7678 68.7276C40.7678 68.1552 40.395 67.6917 39.9347 67.6917L36.7857 67.6913C36.325 67.6913 35.9525 68.1552 35.9525 68.7272C35.9525 69.2995 36.325 69.7635 36.7857 69.7635V69.7635Z"
                fill="#1F78A7"
            />
            <path
                d="M45.7669 69.7634H48.9166C49.3773 69.7634 49.7498 69.2995 49.7498 68.7275C49.7498 68.1552 49.377 67.6917 48.9166 67.6917H45.7669C45.3062 67.6917 44.9337 68.1556 44.9337 68.7275C44.9337 69.2995 45.3065 69.7634 45.7669 69.7634Z"
                fill="#1F78A7"
            />
            <path
                d="M54.7499 69.7634H57.8989C58.3596 69.7634 58.732 69.2995 58.732 68.7275C58.732 68.1552 58.3592 67.6917 57.8989 67.6917H54.7499C54.2892 67.6917 53.9167 68.1556 53.9167 68.7275C53.9167 69.2995 54.2892 69.7634 54.7499 69.7634Z"
                fill="#1F78A7"
            />
            <path
                d="M63.732 69.7634H66.8817C67.3424 69.7634 67.7149 69.2995 67.7149 68.7275C67.7149 68.1552 67.3421 67.6917 66.8817 67.6917H63.732C63.2713 67.6917 62.8989 68.1556 62.8989 68.7275C62.8989 69.2995 63.2717 69.7634 63.732 69.7634V69.7634Z"
                fill="#1F78A7"
            />
        </svg>
    );
}

export default FreeEstimate;
