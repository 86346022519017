import React from "react";

function StressFree() {
    return (
        <svg
            width="103"
            height="103"
            viewBox="0 0 103 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="51.5" cy="51.5" r="51.5" fill="white" />
            <path
                d="M74.5935 56.8844C72.6681 53.2701 69.8239 47.925 69.3437 47.0039C69.2967 41.5163 66.9825 36.2262 62.9785 32.4658C58.8761 28.6132 53.514 26.6879 47.8688 27.0413C38.1744 27.6512 30.1105 35.3837 29.1106 45.0272C28.5784 50.1615 29.9792 55.2225 33.0576 59.3062L33.0581 75.1875C33.0581 76.1872 33.8712 77 34.8703 77H52.5865C53.5856 77 54.3987 76.1868 54.3987 75.1875V69.4696H64.1473C66.0479 69.4696 67.6111 68.0424 67.7838 66.1496L68.3554 59.8753H72.7956C73.5164 59.8753 74.1689 59.5064 74.5403 58.8885C74.9118 58.2706 74.9309 57.5213 74.5927 56.8842L74.5935 56.8844ZM73.3285 58.1606C73.2739 58.2516 73.1133 58.462 72.7963 58.462H67.065L66.3762 66.0218C66.2706 67.1817 65.3127 68.0561 64.1481 68.0561H52.9855V75.188C52.9855 75.4078 52.8066 75.5867 52.5869 75.5867H34.8706C34.6509 75.5867 34.4721 75.4078 34.4721 75.188V58.826L34.3243 58.6351C31.3656 54.8064 30.014 50.0255 30.5179 45.1732C31.4473 36.208 38.9447 29.0203 47.9569 28.4532C48.3679 28.4275 48.7762 28.4146 49.1837 28.4146C53.978 28.4146 58.4934 30.1951 62.0096 33.4974C65.8271 37.0829 67.9292 41.9411 67.9292 47.1764C67.9292 47.3868 67.9292 47.3868 73.344 57.5506C73.4927 57.8307 73.3822 58.0717 73.3276 58.1623L73.3285 58.1606Z"
                fill="#1F78A7"
            />
            <path
                d="M45.8324 34.5C43.2895 34.5 40.8988 35.5228 39.1012 37.3795C36.8863 39.6657 35.6667 42.7058 35.6667 45.9398C35.6667 49.1737 36.8862 52.2136 39.1012 54.5L40.1009 53.4676C38.1533 51.4568 37.0812 48.7836 37.0812 45.9398C37.0812 43.0959 38.1538 40.4228 40.1009 38.412C41.6322 36.8307 43.6672 35.9606 45.8324 35.9606C47.9976 35.9606 50.0331 36.8312 51.5644 38.412C54.0372 40.966 54.0372 45.1214 51.5644 47.6759C50.633 48.6377 49.3938 49.1677 48.0763 49.1677C46.7587 49.1677 45.5195 48.6378 44.5882 47.6759C43.8694 46.9337 43.4734 45.9472 43.4734 44.8977C43.4734 43.8482 43.869 42.8617 44.5882 42.1195C45.1364 41.5533 45.8649 41.2415 46.6406 41.2415C47.4162 41.2415 48.1447 41.5533 48.6931 42.1195C49.1046 42.5449 49.3319 43.1102 49.3319 43.7116C49.3319 44.3131 49.1046 44.8788 48.6931 45.3042C48.0866 45.9296 47.0309 45.9296 46.4258 45.3042C45.981 44.8449 45.981 44.0972 46.4258 43.6378L45.426 42.6054C44.4293 43.6346 44.4293 45.3084 45.426 46.3372C45.9956 46.9258 46.7535 47.2499 47.5593 47.2499C48.3651 47.2499 49.1229 46.9258 49.6933 46.3372C51.0953 44.8898 51.0953 42.5348 49.6933 41.0875C48.8782 40.2453 47.7941 39.7818 46.6407 39.7818C45.4874 39.7818 44.4032 40.2453 43.5882 41.0875C42.6021 42.1057 42.0592 43.4586 42.0592 44.8986C42.0592 46.3387 42.6021 47.6915 43.5882 48.7098C44.7868 49.9476 46.381 50.6297 48.0765 50.6297C49.772 50.6297 51.3657 49.948 52.5649 48.7098C55.5895 45.5864 55.5895 40.5044 52.5649 37.3806C50.7664 35.523 48.3758 34.5001 45.8328 34.5001L45.8324 34.5Z"
                fill="#1F78A7"
            />
        </svg>
    );
}

export default StressFree;
