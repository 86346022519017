import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

import MovingQuickly from "./icons/movingQuickly";
import PotentialInjury from "./icons/potentialInjury";
import ShortTime from "./icons/shortTime";
import StressFree from "./icons/stressFree";
import FreeEstimate from "./icons/freeEstimate";
import RightTools from "./icons/rightTools";

const benefitsList = [
    {
        title: "Expertise and Efficiency",
        description:
            "Professional movers have the experience and skills to handle your belongings safely and efficiently.",
        icon: <MovingQuickly />,
    },
    {
        title: "Stress Reduction",
        description:
            "Outsourcing the physical and logistical aspects of moving reduces your stress.",
        icon: <StressFree />,
    },
    {
        title: "Convenience",
        description: "Offers end-to-end service from packing to unloading.",
        icon: <FreeEstimate />,
    },
    {
        title: "Proper Equipment",
        description:
            "Moving companies have the right tools and vehicles for safe transportation.",
        icon: <RightTools />,
    },
    {
        title: "Safety",
        description:
            "They know how to handle heavy and delicate items, reducing the risk of injury or damage.",
        icon: <PotentialInjury />,
    },
    {
        title: "Insurance and Liability",
        description:
            "Professional movers often provide insurance options to protect your belongings during the move.",
        icon: <ShortTime />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <section className="mt-4 lg:mt-6">
            <div className="max-w-7xl mx-auto">
                <h2
                    className={`text-center font-bold text-xl lg:text-2xl mb-14 mt-14 md:mt-24 ${styles["mainTitle"]}`}
                >
                    The Advantages of Hiring a Top-Rated Moving Company
                </h2>
                <div className="lg:flex flex-wrap justify-between px-4">
                    {benefitsList.map((item, index) => (
                        <div
                            key={index}
                            className={`mb-12 lg:mb-20 w-full lg:w-[30%] p-8 ${styles["item"]}`}
                        >
                            <span>{item.icon}</span>
                            <h3 className="font-bold text-base lg:text-lg my-4">
                                {item.title}
                            </h3>
                            <p className="font-normal text-sm mt-2">
                                {item.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
